<template>
  <div class="px-4 popup_overlay">
    <div class="custom_dialog rounded-xl" style="width: 500px;max-height: 90vh;" v-if="detailObject !== null">
      <div class="relative">
        <div>
          <div class="bg-primary flex items-center justify-between p-3">
            <div class=" font-medium text-white heading-3 overFlowParaA" >
              {{detailObject.userName}}
            </div>
            <div class="flex items-center">
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl ml-2" @click="closePopup(false)">
                <i class="fas fa-times"></i>
              </div>
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl ml-2" @click.stop="showMenuList = true">
                <i class="fas fa-ellipsis-v"></i>
              </div>
              <div v-if="showMenuList">
                <div v-click-outside="closeList">
                  <div class=" bg-white absolute z-10 right-3 shadow-2xl rounded top-10 w-52">
                      <div class="p-2 text-text2 font-medium text-center">
                        <div class="p-2 cursor-pointer hover:bg-blue-50" @click="ignorePopup = true;showMenuList = false">
                          <span class="py-1">Ignore Request</span>
                        </div>
                        <div class="p-2 cursor-pointer hover:bg-blue-50"  @click="isPermenetRemove = false;showMenuList = false;confirmPopup= true">
                          <span class="py-1 text-text2">Temporary Suspend</span>
                        </div>
                        <div class="p-2 cursor-pointer hover:bg-red-50"  @click="isPermenetRemove = true;showMenuList = false;confirmPopup= true">
                          <span class="py-1 text-error">Ban User</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2" style="overflow: auto;max-height: 70vh;">
            <div class="justify-center grid text-center">
              <div class="justify-self-center" v-if="detailObject.profileUrl !== ''">
                <img class="rounded-full row_user_svg border border-gray2 border-opacity-30" :src="detailObject.profileUrl"  v-if="detailObject.profileUrl !== ''">
              </div>
              <div class="justify-self-center" v-if="detailObject.profileUrl === ''">
                <img class="rounded-full row_user_svg" src="@/assets/images/svg/user-color.svg" >
              </div>
              <div class="py-2">
                <div class="text-primary heading-2 font-semibold">{{detailObject.firstName }} {{detailObject.lastName }}</div>
              </div>
            </div>
            <div class="grid xl:grid-cols-2 lg:grid-cols-2">
              <div class="mb-4">
                <p class="text-gray4">Birth Date:</p>
                <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.birthDate | dateTimeWithName}}</p>
              </div>
              <div class="mb-4">
                <p class="text-gray4">Gender:</p>
                <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.gender}}</p>
              </div>
            </div>
            <div class="mb-4">
              <p class="text-gray4">Email:</p>
              <p class="font-semibold heading-5 text-text2 pl-2" v-if="detailObject.emailAddress !== ''">{{detailObject.emailAddress}}</p>
              <p class="font-semibold heading-5 text-text2" v-else>-</p>
            </div>
            <div class="mb-4" v-if="detailObject.profileDescription !== ''">
              <p class="text-gray4">Description:</p>
              <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.profileDescription}}</p>
            </div>
            <div class="mb-4" v-if="detailObject.wayToContact !== ''">
              <p class="text-gray4">Way to Connect:</p>
              <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.wayToContact}}</p>
            </div>
            <div class="mb-4" v-if="detailObject.reportedFirstName !== '' || detailObject.reportedLastName !== ''">
              <p class="text-gray4">Reported By:</p>
              <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.reportedFirstName}} {{detailObject.reportedLastName}} <span class="text-primary" v-if="detailObject.reportedUserName !== ''">(@{{ detailObject.reportedUserName}})</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="confirmPopup" class="popup_overlay relative px-4">
      <div style="width: 600px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-4 font-semibold">
          <p class="heading-4 text-text1">Are You Sure you want to {{isPermenetRemove ? 'permanently ban' : 'Temporary suspend'}} <span class="text-primary">@{{detailObject.userName}}</span> user?</p>
        </div>
        <div class="mb-5 font-semibold" v-if="isPermenetRemove">
          <p class="heading-5 text-text1 mb-4">Once banned cannot be undone</p>
        </div>
        <div class="flex gap-2 justify-end">
          <button class="heading-5 text-white px-3 py-1 rounded bg-gray4" @click="confirmPopup = false">Cancel</button>
          <button class="heading-5 text-white px-3 py-1 rounded bg-error " v-if="isPermenetRemove" @click="deleteUser()">Ban</button>
          <button class="heading-5 text-white px-3 py-1 rounded bg-error" v-if="!isPermenetRemove" @click="deleteUser()">Temporary Suspend</button>
        </div>
      </div>
    </div>
    <div v-if="ignorePopup" class="popup_overlay relative px-4">
      <div style="width: 600px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-6 font-semibold">
          <p class="heading-4 text-text1">Are you sure you want to ignore <span class="text-primary">@{{detailObject.userName}}</span> user report?</p>
        </div>
        <div class="flex gap-2 justify-end">
          <button class="heading-5 text-white px-3 py-1 rounded bg-gray4" @click="ignorePopup = false">Cancel</button>
          <button class="heading-5 text-white px-3 py-1 rounded bg-primary" @click="ingorePost()">Yes</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
export default {
props: ['detail'],
components: {
},
data () {
  return {
    ignorePopup: false,
    confirmPopup: false,
    detailObject: null,
    showMenuList: false,
    isPermenetRemove: false,
  }
},
 created() {
},
mounted () {
  this.getDetail()
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  closeList () {
    this.showMenuList = false
  },
  getDetail () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    ADMINAPI.ReportedUserDetail(
        this.detail.userReportId,
        response => {
          console.log("response", response.Data)
          this.detailObject = response.Data === null ? {} : response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
  },
  ingorePost () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    ADMINAPI.ReportedUserIgnore(
        this.detail.userDetailId,
        response => {
          this.$root.$emit('closeReportedDetail', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
  },
  deleteUser () {
    if (this.isPermenetRemove) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.RemoveAppUser(
        this.detail.userDetailId,
        true,
        response => {
          this.$root.$emit('closeReportedDetail', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    } else {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.ReportedUserTempBlock(
        this.detail.userDetailId,
        response => {
          this.$root.$emit('closeReportedDetail', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  closePopup () {
    this.$root.$emit('closeReportedDetail', false)
  },
},
}
</script>
<style scoped>
.row_user_svg {
  height: 180px;
  width: auto;
}
</style>